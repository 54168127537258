.item-detail {
    display: flex;
    align-items: center;
}

.carousel {
    position: relative;
    max-width: 300px;
    margin-right: 20px;
}

.carousel img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.prev-btn, .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.prev-btn {
    left: 0;
}

.next-btn {
    right: 0;
}

.add-to-cart-btn {
    background-color: #088395;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
}

.add-to-cart-btn:hover {
    background-color: #066d79;
}

.cart {
    margin-top: 20px;
}

.cart ul {
    list-style-type: none;
    padding: 0;
}

.cart li {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.btn-rojo {
    background-color: red;
    color: white;
    cursor: not-allowed;
  }
  