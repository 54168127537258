html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body {
  background-image: url('./inicio.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9); /* Fondo semi-transparente */
  padding: 2rem; /* Espacio interior */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Sombra */
  width: 400px; /* Ancho del formulario */
}

.login-container label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; /* Espacio entre campos */
  width: 100%;
  font-weight: bold;
  color: #174270;
}

.login-container input {
  padding: 0.75rem; /* Espacio interior */
  border: 1px solid #ccc;
  border-radius: 5px; /* Bordes redondeados */
  background-color: #f9f9f9;
  font-size: 1rem;
  width: 100%; /* Ancho completo */
}

.login-container button {
  width: 100%; /* Ancho completo */
  padding: 0.75rem; /* Espacio interior */
  background-color: #273541; /* Color de fondo del botón */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 1rem; /* Tamaño de fuente */
  cursor: pointer; /* Cambia a puntero */
  transition: background-color 0.3s ease; /* Transición suave */
  margin-top: 1rem; /* Espacio superior */
}

.login-container button:hover {
  background-color: #1C2533; /* Color de fondo al pasar el mouse */
}

.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem; /* Espacio superior */
}

.btn-texto {
  background: none; /* Sin fondo */
  border: none; /* Sin borde */
  color: #174270; /* Color azul marino para el texto */
  font-size: 1rem; /* Tamaño de fuente */
  cursor: pointer; /* Cambia a puntero */
  padding: 0; /* Sin padding adicional */
  margin: 0.2rem 0; /* Reduce el margen entre botones */
  transition: color 0.3s; /* Suavizar el cambio de color */
  text-align: center; /* Centra el texto si es necesario */
}

.btn-texto:hover {
  color: #1981a8; /* Color al pasar el mouse */
}

.mensaje {
  color: red;
  margin-top: 20px; /* Añade un margen superior para que no se pegue al formulario */
  text-align: center; /* Centra el texto en la vista */
}


/* Contenedor para la contraseña */
.password-container {
  position: relative; /* Posiciona los elementos relativos uno al otro */
  display: flex;
  align-items: center; /* Alinea verticalmente el botón con el input */
}

/* Estilo del campo de entrada de la contraseña */
.password-container input {
  width: 100%; /* Asegura que el input ocupe todo el ancho posible */
  padding-right: 50px; /* Deja suficiente espacio para el botón a la derecha */
  box-sizing: border-box; /* Mantiene el padding dentro del input */
}

/* Estilo para el botón Mostrar/Ocultar */
/* Estilo para el botón Mostrar/Ocultar */
.password-container .toggle-password {
  position: absolute;
  right: 10px;
  background: none; /* Sin fondo */
  border: none; /* Sin bordes */
  cursor: pointer;
  color: #007bff; /* Color del ícono */
  font-size: 18px; /* Tamaño del ícono */
  padding: 0; /* Elimina el relleno adicional */
  width: auto; /* Ancho automático */
  height: 100%; /* Altura igual al input */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Quitar el borde azul al hacer clic o seleccionar */
.password-container .toggle-password:focus {
  outline: none; /* Quita el contorno de enfoque */
}

/* Quitar el borde al botón en todos los estados */
.password-container .toggle-password:active,
.password-container .toggle-password:focus,
.password-container .toggle-password:hover {
  outline: none; /* Asegúrate de que no haya contorno */
  background: none; /* Asegúrate de que no haya fondo */
  border: none; /* Sin bordes */
}
