.imagen-producto {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.container-imagen {
  width: 150px;
  height: 125px;
  display: inline-block;
}

.container-datos-productos {
  /* border: solid 1px chartreuse; */
  /* align-items: center; */
  border-radius: 5px;
  padding: 10px;

  margin: 10px;
  width: 200px;
  height: 275px;
  display: block;
  box-shadow: 0px 0px 10px 1px rgb(174, 172, 172);
  /* border: solid 1px blueviolet; */
}

.container-datos-productos:hover {
  /* border: solid 1px cyan; */
  background-color: rgba(157, 161, 163, 0.258);
  z-index: 10;
  box-shadow: 0px 0px 15px 2px rgb(174, 172, 172);
}

.informacion-producto {
  font-size: 10px;
}

.info-container {
  width: 100%;
  height: 200px;
  /* background-color: aquamarine; */
  float: left;
}
.contenedor-titulo {
  height: 25%;
  color: #174270;
}
.contenedor-precio {
  height: 10%;
  /* background-color: blueviolet; */
}

.contenedor-cat {
  height: 10%;
  /* background-color: chartreuse; */
}

.contenedor-desc {
  height: 20%;
  /* background-color: aqua; */
}
.informacion-ver {
  height: 2rem;
  width: 90%;
  margin: 1px;
  align-content: center;
  font-size: 14px;
  /* border: solid 1px aquamarine; */
  background-color: #17427097;
  border-radius: 5px;
}
.informacion-ver:hover {
  background-color: #12549bc9;

}
.btn-rojo {
  background-color: red;
  color: white;
  cursor: not-allowed;
}
