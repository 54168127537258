.button-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.vertical-button {
  margin-bottom: 10px;
  text-align: left;
  background-color: white;
  /* background-color: #003366; */
  font-size: 1.8vh;
  color: #003366;
  margin: 0;
  border-radius: 0;
  width: 100;
  border-bottom: solid 1.5px darkgrey;
  padding: 5px;
  margin: 5px;
}

.vertical-button:hover {
  background-color: white;
  color: #003366;
  box-shadow: 0px 0px 15px 2px rgb(174, 172, 172);
  text-decoration: none;
}
.text-categoria{
  color:#003366;
  text-decoration: none;
  font-size: 25px;
  text-align: left;
}