body, html {
    height: 100%; 
    margin: 0; 
    padding: 0; 
    overflow-y: auto; 
}

.contacts-container {
    padding: 40px; 
    max-width: 100%; 
    margin: 0; 
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); 
  /*  background-image: url('../inicio.jpeg'); */
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    min-height: 100vh; 
    color: #fff; 
    overflow: auto; 
}



h1 {
    text-align: center;
    color: #fff; 
    margin-bottom: 40px; 
    font-size: 4rem; /* Aumentado */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
    letter-spacing: 3px; /* Aumentado */
}

h2 {
    color: #007bff; 
    margin-bottom: 20px; 
    font-size: 2.5rem; /* Aumentado */
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); 
    border-bottom: 2px solid #007bff; 
    padding-bottom: 10px; 
}

h3 {
    color: #0056b3; 
    margin-bottom: 15px; 
    font-size: 2rem; /* Aumentado */
    font-weight: 600; 
}

.contact-info, .about-us, .location {
    background-color: rgba(255, 255, 255, 0.71); /* Más opaco */
    padding: 30px; /* Aumentado */
    border: 1px solid rgba(0, 0, 0, 0.2); 
    border-radius: 10px; /* Bordes más redondeados */
    transition: box-shadow 0.3s ease; 
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2); /* Sombra más intensa */
}

.contact-info:hover, .about-us:hover {
    box-shadow: 0 10px 40px rgba(0, 123, 255, 0.5); /* Sombra más intensa al pasar el cursor */
}

.email-link {
    color: blue;
    text-decoration: underline;
    margin-left: 5px; /* Espacio entre el texto y el enlace */
  }


p {
    line-height: 1.8; /* Aumentado */
    color: #333; /* Cambiado a gris oscuro para mejor legibilidad */
    font-size: 1.5rem; /* Aumentado */
    margin-bottom: 15px; 
    text-align: justify; 
}

ul {
    list-style-type: none;
    /* padding: 0; */
    margin: 0;
    padding-left: 5px;
}

ul li {
    margin: 15px 0; 
}

a {
    color: #007bff; 
    text-decoration: none; 
    font-weight: bold; 
    font-size: 1.2rem; /* Aumentado */
    transition: color 0.3s ease, text-shadow 0.3s ease; 
}

a:hover {
    text-decoration: underline; 
    color: #0056b3; 
    text-shadow: 0 0 5px rgba(0, 123, 255, 0.7); 
}

strong {
    color: #333;
}

@media (max-width: 600px) {
    .contacts-container {
        padding: 20px; 
    }

    h1 {
        font-size: 3rem; /* Reducción para móviles */
    }

    h2 {
        font-size: 2rem; /* Reducción para móviles */
    }

    h3 {
        font-size: 1.5rem; /* Reducción para móviles */
    }

    p, a {
        font-size: 1rem; 
    }
}