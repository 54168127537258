* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
    margin: 0;
    background-color: #4A90E2;
    overflow: auto; /* Cambiado a auto */
}

.carrito-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px; /* Ajustado */
}

.producto {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.producto-imagen {
    width: 150px; /* Ajustado */
    height: auto;
    margin-right: 10px;
}

.producto-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.producto-nombre {
    font-weight: bold;
    text-align: left;
    font-size: 18px; /* Ajustado */
}

.producto-codigo {
    font-size: 14px; /* Ajustado */
    margin-top: 5px;
    text-align: left;
}

.cantidad-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.btn-cantidad {
    background-color: #e0e0e0;
    border: none;
    padding: 5px 10px;
    margin: 0 10px;
    cursor: pointer;
}

.btn-eliminar {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 20px;
}

.resumen-compra {
    margin-top: 10px;
    text-align: left;
    width: 100%;
    padding: 0; /* Ajustado */
    position: relative;
    font-size: 36px;
}

.resumen-compra > h3 {
    font-size: 48px; /* Ajustado */
}

.btn-finalizar, .btn-continuar {
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 24px; /* Ajustado */
    margin-right: 8px;
}

.btn-finalizar {
    background-color: #4caf50;
}

.btn-continuar {
    background-color: #2196f3;
}

