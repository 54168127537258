.App {
  text-align: center;
  height: 25%;
}

/* Imagen de fondo */
.App-inicio {
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: -2;
}

/* Icono en la esquina superior izquierda */
.icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 85px; /* Aumenta el ancho */
  height: 85px; /* Aumenta el alto */
  z-index: 2;
  background-image: url('icono.jpeg');
  background-size: cover;
  background-position: center;
}

/* Botones en la esquina superior derecha */
/* Asegúrate de que el fondo de toda la página sea blanco */
body {
  margin: 0;
  background-color: rgb(60, 36, 147); /* Fondo blanco para toda la página */
}

.header-nav {
  position: fixed; /* Usa fixed para que la barra de navegación esté siempre en la parte superior */
  top: 0;
  left: 0;
  right: 0; /* Extiende la barra de navegación a todo el ancho de la pantalla */
  display: flex;
  justify-content: flex-end; /* Alinea los botones a la derecha */
  gap: 20px;
  background-color: rgb(56, 41, 165); /* Fondo blanco para el contenedor */
  padding: 35px 20px; /* Espacio alrededor de los botones */
  border-radius: 0; /* Sin borde redondeado */
  z-index: 1; /* Asegura que la barra de navegación esté por encima de otros elementos */
}

.header-nav a {
  color: black; /* Color del texto en negro */
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra negra con transparencia */
  transition: box-shadow 0.3s ease; /* Transición suave para el efecto hover */
}

/* Superposición de sombra */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.61);
  z-index: -1;
}

/* Contenedor del encabezado */
.App-header {
  position: static;
  z-index: 1;
  text-align: center;
  color: whitesmoke;
  padding: 100px;
}

/**TEXTO DE BIENVENIDO PEDIDOS PERSIA */
.welcome-container {
  position: absolute;
  top: 1px;
  right: 950px; /* Ajusta este valor según sea necesario para posicionar el texto a la derecha del icono */
  z-index: 2; /* Asegura que el texto esté por encima de la imagen de fondo */
  display: flex;
  align-items: center;
}

.welcome-text {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  background-color: rgba(241, 240, 240, 0.8); /* Fondo blanco con transparencia */
  padding: 5px 10px; /* Agrega un poco de espacio alrededor del texto */
  border-radius: 5px; /* Bordes redondeados */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}