.informacion-perfil-container {
    max-width: 800px; /* Tamaño máximo del contenedor */
    margin: 40px auto; /* Centra el contenedor */
    padding: 20px; /* Espaciado interno */
    background-color: #f9f9f9; /* Fondo gris claro */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra */
   
}

h1 {
    text-align: center; /* Centra el título */
    margin-bottom: 20px; /* Espaciado debajo del título */
}

form {
    display: flex; /* Flexbox para el formulario */
    flex-direction: column; /* Apila los elementos verticalmente */
    gap: 15px; /* Espaciado entre los elementos */
}

label {
    display: flex; /* Flexbox para etiquetas */
    flex-direction: column; /* Apila el texto y el input */
}

input, select {
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ccc; /* Borde gris claro */
    border-radius: 4px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de fuente */
}

button {
    padding: 12px; /* Espaciado interno */
    background-color: #007bff; /* Color de fondo */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cambia el cursor al pasar el mouse */
    transition: background-color 0.3s; /* Transición suave */
}

button:hover {
    background-color: #273541; /* Color al pasar el mouse */
}