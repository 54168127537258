.App {
  text-align: center;
 
}

/* Imagen de fondo */
.App-inicio {
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: -2;
}

/* Botones en la esquina superior derecha */
.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  background-color: rgb(197, 193, 193);
  padding: 35px 20px;
  border-radius: 0;
  z-index: 1;
}

.header-nav a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
}

/* Superposición de sombra */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.61);
  z-index: -1;
}

/* Contenedor del encabezado */
.App-header {
  position: static;
  z-index: 1;
  text-align: center;
  color: whitesmoke;
  padding: 100px;
}

/**TEXTO DE BIENVENIDO PEDIDOS PERSIA */
.welcome-container {
  position: absolute;
  top: 1px;
  right: 950px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.welcome-text {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  background-color: rgba(241, 240, 240, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background-image: url('./inicio.jpeg');
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  font-family: Arial, sans-serif;
}

.register-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  padding: 2.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.register-container h2 {
  margin-bottom: 2.5rem;
  color: #ffffff;
  background-color: #003366; /* Azul marino */
  padding: 1.2rem;
  border-radius: 0.7rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.register-container label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.8rem;
  font-weight: bold;
  color: #273541;
}

.register-container input {
  padding: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.register-container button {
  width: 100%;
  padding: 0.9rem;
  background-color: #273541;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem; /* Ajusta este valor para controlar el espacio */
}

.register-container button:hover {
  background-color: #1C2533;
  }

.password-error{
 color: #f20808;
}

 