.add-product-container {
   
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  /* Asegura que los select tengan el mismo tamaño que los inputs */
select {
    width: 100%;
    height: 40px; /* Ajusta según el tamaño de tus inputs */
    padding: 8px; /* Similar al padding de los inputs */
    font-size: 16px; /* Asegura que la fuente sea del mismo tamaño */
    border: 1px solid #ccc; /* Bordes igual que los inputs */
    border-radius: 4px; /* Si los inputs tienen bordes redondeados */
    box-sizing: border-box; /* Asegura que el tamaño del padding no afecte la altura */
  }
  
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  label {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
  }
  
  input {
    padding: 8px;
    /* font-size: 0.5rem; */
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 10px;
    font-size: 1.1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .message {
    margin-top: 20px;
    color: green;
    text-align: center;
  }
  
  .image-form {
    margin-top: 30px;
  }

  .finalizar-pedido-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    background-color: white;
  }
  
  .form-container {
    flex: 1;
    max-width: 45%;
  }
  
  .resumen-container {
    flex: 1;
    max-width: 45%;
    border-left: 1px solid #ddd;
    padding-left: 20px;
  }
  
  .product-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .product-form label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .product-form input,
  .product-form select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .resumen-producto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .resumen-producto-imagen {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .resumen-producto-info {
    flex: 1;
    margin-left: 10px;
  }
  
  .resumen-total {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .btn-finalizar {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .btn-descargar {
    background-color: #d8290a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  .btn-finalizar:hover {
    background-color: #218838;
  }
  